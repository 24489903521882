import { useWeb3Modal } from "@web3modal/wagmi/react";
import { StyledButton } from "./AppComponents";
import { getAccount } from "@wagmi/core";
import { useMediaQuery } from "@mui/material";

export const ExampleButton = () => {
  const { open } = useWeb3Modal();
  const { address } = getAccount();
  const matches = useMediaQuery("(max-width:1279px)");

  return (
    <StyledButton
      width={matches ? "160px" : "200px"}
      onClick={async () => {
        // await open();
      }}
    >
      {address
        ? address.slice(0, 4) + "..." + address.slice(-4)
        : "Connect Wallet"}
    </StyledButton>
  );
};
